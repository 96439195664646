import React from 'react'
import "./Footer.css"
import { useNavigate } from 'react-router-dom';

import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt, FaFacebook } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";

function Footer() {
  const navigate = useNavigate();

    const logoImgSrc = "https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MM_GROUP_logo_ll6R1mm_7.png"
    // const logoImgSrc = "images/MM_GROUP_logo.png"
    const footerBg = "https://ik.imagekit.io/qljqw3tvn/tr:w-1999/MMGroup/misc/Group13_qIXwUUKjIl.png"

  return (
    <div className='footer-container'>

      <div className="top-bg">
        <img 
        src={footerBg} alt="" />
      </div>

      <div className="footer-grid-box">
        {/* ////Logo/// */}
        <section className='footer-grid-section'>
          <img className='footer-logo' src={logoImgSrc} alt="" />
        </section>

        {/* ///Contact//// */}
        <section className='footer-grid-section'>

            <a href='https://www.google.com/maps/place/88,+Muktaram+Babu+St,+College+Street+Market,+College+Street,+Kolkata,+West+Bengal+700007/@22.5822942,88.3601134,17z/data=!3m1!4b1!4m6!3m5!1s0x3a0277b4ace5cbab:0x199cfa629b8081ba!8m2!3d22.5822942!4d88.3626883!16s%2Fg%2F11c1y3cv9g?entry=ttu' 
              target="_blank" rel="noreferrer" aria-label=''>
                <FaLocationDot className='footer-icon small-icon'/>
                <p className='footer-link'>
                88 Muktaram Babu Street, Near Ram Mandir, Kolkata - 700007
                </p>
            </a>
            
            <a href='tel: +919830975522' aria-label=''>
              <FaPhoneAlt className='footer-icon small-icon'/>
              <p className='footer-link'>
                +91 9830975522
              </p>
            </a>
            
            <a href="mailto:info@mmgroupfashion.com" aria-label=''>
              <IoMailSharp className='footer-icon small-icon'/>
              <p className='footer-link'>
                info@mmgroupfashion.com
              </p>
            </a>
          
          <br />
          
          <div className="social-icon-box">

            <a href='https://www.instagram.com/mmgroupfashion/' target="_blank" rel="noreferrer" aria-label=''>
              <AiFillInstagram className='footer-icon instagram'/>
            </a>

            <a href='https://www.facebook.com/mmgroupfashion/' 
              target="_blank" rel="noreferrer" aria-label=''
              
              >
              <FaFacebook className='footer-icon facebook'/>
            </a>
          </div>
        
        </section>

        <section className='footer-nav-section'>
          {/* <h3>Navigate</h3> */}
          
          <p onClick={()=>navigate('/')}>
            Home
          </p>

          <p onClick={()=>navigate('/about-us')}>
            About
          </p>
          
          <p onClick={()=>navigate('/brands')}>
            Brands
          </p>
          
          <p onClick={()=>navigate('/careers')}>
            Careers
          </p>
          
          <p onClick={()=>navigate('/partners')}>
            Partners
          </p>
          
          <p onClick={()=>navigate('/contact-us')}>
            Contact
          </p>
        
        </section>
      </div>
        {/* <h1>soem</h1> */}
      
      <p className="rights-reserved">

        All Rights Reserved © 2024.
      </p>
    </div>
  )
}

export default Footer