const partnerList = [
    {
        partnerName:`G. G. APPARELS`,
        partnerAddress:[`99 DADI SETH AGYARI LANE 3RD FLOOR`,
                        `KALBADEVI`,
                        `MUMBAI - 2`
                        ],
        partnerPhone:`9324288589`,
    },
    {
        partnerName:`GALAXY APPARELS`,
        partnerAddress:[`4-8-28/18, S. S. GARMENT COMPLEX,`,
                        `PUTLI BAWLI KOTHI,`,
                        `HYDERABAD – 95`
                        ],
        partnerPhone:`9396426342`
    },
    {
        partnerName:`MEHTA HOSIERY`,
        partnerAddress:[`6/11 PADMAVATI COMPLEX,`,
                        `2ND FLOOR SANTHUSHA PET,`,
                        `BANGLORE - 53`
                        ],
        partnerPhone:`9341231383`
    },
    {
        partnerName:`MAHABHISHEK FASHION`,
        partnerAddress:[`23, 1 st Floor`,
                        `S.S.D Complex`,
                        `S.V. Lane, Mamulpet`,
                        `Bangaluru - 53`
                    ],
        partnerPhone:``
    },
    {
        partnerName:`LEKHUS COLLECTION PVT LTD`,
        partnerAddress:[`3024 Hardhian Singh Road`,
                        `Opp – Baldev Band`,
                        `Karol Bagh`,
                        `New Delhi - 5`
                    ],
        partnerPhone:`+919810238609`
    },
    {
        partnerName:`ROOPALI KNITS`,
        partnerAddress:[`X-247 Gali No- 0`,
                        `Mohalla Ram Nagar`,
                        `Gandhinagar`,
                        `Delhi – 110 031`
                    ],
        partnerPhone:`9810108227`
    },
    {
        partnerName:`RAJEEV DRESSES PVT. LTD.`,
        partnerAddress:[`X-261, MAIN LANE,`,
                        `RAMNAGAR MARKET,`,
                        `GANDHI NAGAR,`,
                        `DELHI - 31`
                    ],
        partnerPhone:`9899104222`
    },
    {
        partnerName:`SHYAM SONS`,
        partnerAddress:[`C/O Shruti Fashion`,
                        `Khatri Complex`,
                        `Walcott Compound`,
                        `Amravati (M.S)`
                    ],
        partnerPhone:``
    },
    {
        partnerName:`ROOPALI TRADERS`,
        partnerAddress:[`X-404, Gali No. 2`,
                        `Mohala Ramnagar`,
                        `Gandhi Nagar`,
                        `Delhi – 110 031`
                    ],
        partnerPhone:`09811684570`
    },
    {
        partnerName:`TIKOLO APPARELS PVT. LTD.`,
        partnerAddress:[`RG-1 & 2, Vaibhav Laxmi Market`,
                        `Gheekanta Road,`,
                        `Ahmedabad`
                    ],
        partnerPhone:`7600770660`
    },
]

export default partnerList