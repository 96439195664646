import React, { useEffect } from 'react'
import PartnerDropdown from '../../components/Dropdown/PartnerDropdown'
import { useMediaQuery } from 'react-responsive'
import PartnerDDMobile from '../../components/Dropdown/PartnerDDMobile'

function Partners() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' }) 

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <div className="header-section">

      <h1>Partners</h1>

    </div>

    {isSmallWindow?
      <section className='full-content-section'>
        <PartnerDDMobile />
      </section>
      :
      <section className="half-grid-section">
      <PartnerDropdown />
    </section>}
    
    </>
  )
}

export default Partners