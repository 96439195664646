import React, { useState } from 'react'
import "./Dropdown.css"

import partnerList from '../Lists/PartnerList'

function PartnerDropdown() {
    const [ activeIndex, setActiveIndex ] = useState(0)
  return (
    <div className='partner-dd-container'>

        
        
        <div className="partner-list-box">
            {partnerList.map((partner,index)=>{
                return(
                    <div className='partner-cell-box' key={index}
                        onClick={()=>setActiveIndex(index)}
                    >
                        <p className='partner-cell-text cell-num'>
                            {index + 1}
                        </p>
                        <p className='partner-cell-text'>
                            {partner.partnerName}
                        </p>
                    </div>
                )
            })}

        </div>
    
        <div className="partner-info-box">
            <section className='partner-info-heading'>
                <p style={{fontSize:`3rem`}}
                >
                    {activeIndex<9?'0':''}{activeIndex+1}.
                </p>

                <p >
                    {partnerList[activeIndex].partnerName}
                </p>

            </section>

            <section className="partner-info-details">
                {partnerList[activeIndex].partnerAddress.map((addLine,i)=>{
                    return (
                        // <a 
                            // href={}
                            // key={i}
                        // >
                            <p className='partner-info-para'
                                key={i}
                            >
                                {addLine}
                            </p>
                        // </a>
                    )                
                    }
                )}
    
                <br />
    
                <p className='partner-info-para'>
                    {partnerList[activeIndex].partnerPhone}
                </p>
            
            </section>

            {/* <div className="custom-shape-divider-bottom-1715064441">
                <svg data-name="Layer 1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 1200 120" 
                    preserveAspectRatio="none"
                >
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                </svg>
            </div> */}
        
        </div>
    
    </div>
  )
}

export default PartnerDropdown