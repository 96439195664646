import React, {useEffect, useState} from 'react'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from "react-icons/io";
import { GoDotFill, GoDot } from "react-icons/go";
import { motion, useAnimation } from 'framer-motion'

import CoverCarouselItem from './CoverCarouselItem';

import "./CoverCarousel.css"

function CoverCarousel() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [hoverFlag, setHoverFlag] = useState(false)

  const animation1 = useAnimation()
  // const [autoScrollFlag, setAutoScrollFlag] = useState(true)
  
  const imgList = [
    // {
    //   src:'https://ik.imagekit.io/qljqw3tvn/MMGroup/bannersdesktopPC_WMRoPi391.jpg',
    //   tags:[]
    // },
    // {
    //   src:"https://ik.imagekit.io/qljqw3tvn/MMGroup/Group%2030_lbN-WigHz.png",
    //   tags:[]
    // },
    {
      src:"./images/MagicWearWhite.png",
      tags:[]
    },
    // {
    //   src:"./images/MagicWear.png",
    //   tags:[]
    // },
    {
      src:"./images/MagicClub.png",
      tags:[]
    },
    // {
    //   src:"./images/MM3162.jpg",
    //   tags:[]
    // },
    // {
    //   src:"./images/MM3312.jpg",
    //   tags:[]
    // },
    // {
    //   src:"./images/banner_2_2000xOBORI.webp",
    //   tags:[]
    // },
    // {
    //   src:"./images/banner_1_2000xOBORI.webp",
    //   tags:[]
    // },
  ]

  /////////////////////////////////////////////////
  /////////////////Functions///////////////////////
  /////////////////////////////////////////////////
  
  const handleCarosuelBtnClick = (index) =>{
    // clearTimeout(autoTimeout)
    
    if(index<0){
      // index = 0
      index = imgList.length - 1
    }else if(index>imgList.length-1){
      // index = imgList.length - 1
      index = 0 
    }
    
    setActiveIndex(index)
  }

  function autoScroll(){
    if(activeIndex===imgList.length-1){
      handleCarosuelBtnClick(0)
    }else{
      handleCarosuelBtnClick(activeIndex+1)
    }
  }
  
  /////////////////////////////////////////////////
  /////////////////////Hooks///////////////////////
  /////////////////////////////////////////////////

  useEffect(()=>{
    const autoTimeout = setTimeout(autoScroll, 6000);
    // if(autoScrollFlag){
    // }
    return ()=>clearTimeout(autoTimeout)
    // else if(activeIndex===imgList.length-1){
    //   setAutoScrollFlag(false)
    // }
  },[activeIndex])

  useEffect(()=>{
    if(hoverFlag){
      animation1.start('visible')
    } else if(!hoverFlag){
      animation1.start('hidden')
    }
  },[hoverFlag])

  return (
    <div className='cover-carousel-container'
      onMouseEnter={()=>setHoverFlag(true)}
      onMouseLeave={()=>setHoverFlag(false)}
    >
      
      <div className="carousel-inner-container"
        style={{transform:`translate(-${activeIndex * 100}vw)`}}
        // onDrag={{}}
      >

        {imgList.map((imgItem,index)=>{
          return (
            <CoverCarouselItem item={imgItem} key={index} />
            )
        })}
      </div>
      
      {/* <motion.div className={`carousel-arrow-btn-left`}
        variants={{
          hidden:{x:"-100%", opacity:"0"},
          visible:{x:"50%",opacity:"1",transition:{duration:0.6,ease:[0.6,0.01,0.22,0.93]}}
        }} 
        initial="hidden" 
        animate={animation1}
        onClick={()=>handleCarosuelBtnClick(activeIndex-1)}
      >
        <FaChevronLeft 
        /> 
      </motion.div> */}
      
      {/* <div className="carousel-arrow-btn-right"
        onClick={()=>handleCarosuelBtnClick(activeIndex+1)}
      >
        <FaChevronRight 
        />
      </div> */}
      
      <div className="carousel-btn-box"
        // variants={{
        //   hidden:{bottom:"-20%",left:"50%", opacity:"0",transform: `translate(-50%,0%)`},
        //   visible:{bottom:"10%",left:"50%",transform: `translate(-50%,0%)`, opacity:"1",
        //             transition:{duration:0.6,ease:[0.6,0.01,0.22,0.93]
        //           }}
        // }} 
        // initial="hidden" 
        // animate={animation1}
      >
        {imgList.map((imgItem, index)=>{
          return(
            <React.Fragment key={index}>
            {index===activeIndex?
              <GoDotFill className='carousel-btn'  />
              :
              <GoDot className='carousel-btn'
                onClick={()=>handleCarosuelBtnClick(index)}
              />
            }
            </React.Fragment>
          )
        })}
      </div>
      

    </div>
  )
}

export default CoverCarousel