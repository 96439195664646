import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import './App.css';

import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';
import Brands from './pages/Brands/Brands';
import Careers from './pages/Career/Careers';
import Partners from './pages/Partners/Partners';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import { useMediaQuery } from 'react-responsive';
import NavbarMobile from './components/Navbar/NavbarMobile';
import NotFound from './pages/NotFound/NotFound';

function App() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' }) 

  return (
    <>
    <Router>
    
      <div className='container'>
      {isSmallWindow?
        <NavbarMobile />
        :
        <Navbar />
      }
    
      <Routes>

        <Route path='/' element={<Home />} />
        
        <Route path='/brands/:brand' element={<Brands />} />
        
        <Route path='/careers' element={<Careers />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path="*" element={<NotFound />} />
        
      </Routes>

      <Footer />
      </div>
    
    </Router>
    </>
  );
}

export default App;
