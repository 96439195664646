import React, { useEffect } from 'react'
import CareerForm from '../../components/Form/CareerForm'

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    
    <>
    <div className="header-section"
    >

      <h1>Careers</h1>
    </div>

    <div className="half-content-section"
      style={{marginBottom:`30vh`}}
    >

    <CareerForm />
    </div>
    </>
  )
}

export default Careers