const brandList = [
  {
    brandName:`Magic Club`,
    brandCoverImg:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub_popup_xcZG6gz_M5.png`,
    brandDesc:[`Magic Club description #1`,
                `Magic Club description #2`,
                `Magic Club description #3`,
                `Magic Club description #4`,
              ],
    brandLogoSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MAGIC%20CLUB-LOGO_rBd3pcTLPE.png`,
    slug:`magicclub`,
    brandIndex:`0`,
  },
  {
    brandName:`Magic Baby`,
    brandCoverImg:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby_popup_29XI4dlBt.png?updatedAt=1714153022911`,
    brandDesc:[`Magic Baby description #1`,
                `Magic Baby description #2`,
                `Magic Baby description #3`,
                `Magic Baby description #4`,
              ],
    brandLogoSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MAGIC%20BABY_VJW_W9PLpE.png`,
    slug:`magicbaby`, 
    brandIndex:`1`,
  },
  {
    brandName:`Magic Wear`,
    brandCoverImg:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear_popup_h6bvXXo2Q.png`,
    brandDesc:[`Magic Wear description #1`,
                `Magic Wear description #2`,
                `Magic Wear description #3`,
                `Magic Wear description #4`,
              ],
    brandLogoSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/Magic%20Wear%20LOGO_OjyrpJSru.png`,
    slug:`magicwear`,
    brandIndex:`2`,
  },
  {
    brandName:`Mini Angel`,
    brandCoverImg:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic_popup_Ws2kTV7Cp.png`,
    brandDesc:[`Mini Angel description #1`,
                `Mini Angel description #2`,
                `Mini Angel description #3`,
                `Mini Angel description #4`,
              ],
    brandLogoSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MINI%20ANGEL_kdIrX3kZp.png`,
    slug:`miniangel`,
    brandIndex:`3`, 
  },
  {
    brandName:`Mini Magic`,
    brandCoverImg:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel_popup_K9ZSc2fmt.png`,
    brandDesc:[`Mini Magic description #1`,
                `Mini Magic description #2`,
                `Mini Magic description #3`,
                `Mini Magic description #4`,
              ],
    brandLogoSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MINI%20MAGIC_PLgnQUN4mv.png`,
    slug:`minimagic`,
    brandIndex:`4`,
  },  
]

  export default brandList