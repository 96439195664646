import React, { useState } from 'react'
import "./Form.css"
import { AnimatePresence } from 'framer-motion'

function CareerForm() {
    const [errorPopup, setErrorPopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)

    const [formData, setFormData] = useState({
        applicantName:'',
        applicantPhone:'',
        applicantEmail:'',
        applicantDOB:'',
        appliacntGender:'', //
        applicantRole:'', //
        applicantDesgination:'', //

        applicantGraduation:'',
        applicantGraduationInstitute:'',
        applicantGraduationYearOfPassing:'',
        
        applicantPostGraduation:'',
        applicantPostGraduationInstitute:'',
        applicantPostGraduationYearOfPassing:'',

        applicantCV:``,

        applicantDeclarationFlag:'',
    })

    const {applicantName, 
        applicantPhone, 
        applicantEmail,
        applicantDOB,
        appliacntGender,
        applicantRole,
        applicantDesgination,
        
        applicantGraduation, //
        applicantGraduationInstitute, //
        applicantGraduationYearOfPassing, //

        applicantPostGraduation,
        applicantPostGraduationInstitute,
        applicantPostGraduationYearOfPassing,

        applicantCV,
        applicantDeclarationFlag
    } = formData

    /////////////////////////////////////////////////
    //////// Functions /////////////////////////////
    ////////////////////////////////////////////////

    const onChange = (e) => {
        setFormData((prevState)=>({
            ...prevState, 
            [e.target.name] : e.target.value,
        }))
        // console.log(`Entered onChange`)
    }

    const handleNumField = (e) => {
        const value = e.target.value.replace(/[^+-0123456789\s]/g, "");
        // const value = tempvalue.replace(/[]/g,)
        setFormData((prevState)=>({...prevState, [e.target.name]:value}));
      };

    const onSubmit = (e) => {
        e.preventDefault()
        
        if(applicantName==='' || 
            applicantEmail==='' || 
            applicantPhone==='' ||
            applicantDOB==='' ||
            applicantRole==='' ||
            applicantCV
        ){
            setErrorPopup(true)
            setTimeout(()=>{
                setErrorPopup(false)
              },2000)
        }else{

            const userData = {
                applicantName, 
                applicantPhone, 
                applicantEmail,
                applicantDOB,
                appliacntGender,
                applicantRole,
                applicantDesgination,
                
                applicantGraduation,
                applicantGraduationInstitute,
                applicantGraduationYearOfPassing,

                applicantPostGraduation,
                applicantPostGraduationInstitute,
                applicantPostGraduationYearOfPassing,

                applicantDeclarationFlag
            }
            console.log(`userData: ${JSON.stringify(userData,null,4)}`)

            setSuccessPopup(true)
                setTimeout(()=>{
                    setSuccessPopup(false)
                },2000)

            // emailjs.sendForm(service_ID,
            //     template_ID, 
            //     e.target,
            //     email_Key)
            //     .then(res=>{
            //         // console.log(res)
            //             setSuccessPopup(true)
            //             setTimeout(()=>{
            //                 setSuccessPopup(false)
            //             },4000)
            //     }).catch(err=> console.log(err))
        }
    }

  return (
    <div className='career-form-container'
        style={{alignItems:`center`}}
    >
        <form onSubmit={onSubmit} className='form-container career-grid-container'>
            
            <div className="form-grid career-form-grid">

                <div className="form-group">
                    <label>Name *</label>
                    <input type = "text" 
                        className="form-control" 
                        name = 'applicantName'
                        id = 'applicantName'
                        value = {applicantName} 
                        placeholder= ''
                        onChange = {onChange}
                        autoComplete="off"
                        required
                        />
                </div>


                <div className="form-group" style={{gridColumn: `1 / span 2`}}>
                    <label>E-mail *</label>
                    <input type="email" 
                        className="form-control" 
                        name='applicantEmail'
                        id='applicantEmail' 
                        value={applicantEmail} 
                        placeholder=''
                        onChange={onChange}
                        autoComplete="off"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Phone *</label>
                    <input type="text" 
                        className="form-control" 
                        name='applicantPhone'
                        id='applicantPhone' 
                        value={applicantPhone} 
                        placeholder=''
                        onChange={handleNumField}
                        required
                        // onKeyPress={(e) => !/[0-9,.,+, ]/.test(e.key) && e.preventDefault()}
                         />
                </div>

                <div className="form-group">
                    <label>Date of Birth *</label>
                    <input type="date" 
                        className="form-control" 
                        name='applicantDOB'
                        id='applicantDOB'
                        value={applicantDOB}
                        placeholder=''
                        onChange={onChange}
                        required
                    />
                </div>


                    
                {/* <div className="alert-submit-box">


                    <AnimatePresence>
                        {errorPopup &&
                            <div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            >
                                <div className="error-alert">
                                    Please fill all the fields
                                </div>
                            </div>
                        } 
                    </AnimatePresence>

                    <AnimatePresence>
                        {successPopup && 
                         <div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            > 
                                <div className="error-alert success-alert">
                                    We will get back to you soon!
                                </div>
                        </div>
                        }
                    </AnimatePresence>

                </div> */}
            </div>

            <div className="form-grid career-form-grid">
                {/* <div className="form-group">
                    <label htmlFor="">Role *</label>
                    <select 
                        className='form-control'
                        name="applicantRole" 
                        id="applicantRole"
                        value={applicantRole}
                        onChange={onChange}
                    >
                        <option value="Designer">Designer</option>
                        <option value="Merchandiser">Merchandiser</option>
                    </select>
                </div> */}

                <div className="form-group">
                    <label htmlFor='applicantCV'>Resume *</label>
                    <input type="file" 
                        className="form-control file-control" 
                        name='applicantCV'
                        id='applicantCV' 
                        value={applicantCV} 
                        placeholder=''
                        onChange={onChange}
                        autoComplete="off"
                        required
                    />
                </div>

                <div className="form-group checkbox-group">
                    <input type="checkbox" 
                        className="form-control checkbox-control" 
                        name='applicantDeclarationFlag'
                        id='applicantDeclarationFlag' 
                        value={applicantDeclarationFlag} 
                        placeholder='E-mail'
                        onChange={onChange}
                        autoComplete="off"
                        required
                    />
                    <label htmlFor="applicantDeclarationFlag">
                        I hereby declare that the ownership of the works above has been marked correctly by me and I may be disqualified if the information is found to be incorrect.
                    </label>
                    
                </div>

                <div className="form-group">
                    <button type="submit" className="submit-btn">
                        Submit
                    </button>
                </div>
            </div>
            
            

        </form>
    </div>
  )
}

export default CareerForm