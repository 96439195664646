import React from 'react'
import "./CoverCarousel.css"

function CoverCarouselItem({item}) {
  return (
    <div className='cover-carousel-item-container'>
        <img className="banner-img"
            src={item.src}
            alt='img'
        />
    </div>
  )
}

export default CoverCarouselItem