const ImageList = [
    ///Mini Magic///
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4876_idn1GFURe.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4788_CXQ1V1N2w.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4871_MD2fwJMDN.jpg`,
        slug:`minimagic`,

    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4837_kafJZfmaFd.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4798_toq3HtATsM.jpg`,
        slug:`minimagic`,
    },

    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4829_v2RLBlyz8.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4840_5uAFTr6cK5.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4838_jEJrpXVF2r.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4836_QzEIGHvwUR.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4852_IkSVZXKyTV.jpg`,
        slug:`minimagic`,
    },

    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4848_VbrvAipbhA.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4792_W0JaRfkXT.jpg`,
        slug:`minimagic`,
    },
    {
        brand:`Mini Magic`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/4866_PhCpC1HRN.jpg`,
        slug:`minimagic`,
    },
    // {
    //     brand:`Mini Magic`,
    //     imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/MM1_3DtHaabIlC.jpg`,
    //     slug:`minimagic`,
    // },
    // {
    //     brand:`Mini Magic`,
    //     imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic/MM5_-yn1ML7fz.jpg`,
    //     slug:`minimagic`,
    // },

    ///Mini Angel///
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2860_icM5A_Euo-.jpg`,
        slug:`miniangel`,
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2869-damaged_HXbFeYKSTX.jpg`,
        slug:`miniangel`,
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2811_OgUHBqyB_-.jpg`,
        slug:`miniangel`,
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2874__bdhbR7sUa.jpg`,
        slug:`miniangel`,
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2832_1rbaLJ3kLL.jpg`,
        slug:`miniangel`,
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2844_c_zEBof-E6.jpg`,
        slug:`miniangel`,
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2845_tcQUAxBNQ.jpg`,
        slug:`miniangel`
    },
    {
        brand:`Mini Angel`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel/2831-damaged_gKqjYuFD3r.jpg`,
        slug:`miniangel`,
    },

    ///Magic Baby///
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3317-damaged_3BOPcQGqnR.jpg?updatedAt=1715074276929`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3407_d32wSm5OHj.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3427_pSgXZex8rh.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3396_IN81pa1miJ.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3385_gtoHsMVgl1.jpg`,
        slug:`magicbaby`,
    },

    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3361_7l4fmqXHK4.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3332-damaged_-ItgeP-C_6.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3349_suYC3rSgCb.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3390_TsD8ckoWy2.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3389_IFeuHt_iA4.jpg`,
        slug:`magicbaby`,
    },

    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3354_2sjwvhlCG-.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3360_d9W-HzKdba.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3370_pt4FvAgjeD.jpg`,
        slug:`magicbaby`,
    },
    {
        brand:`Magic Baby`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby/3377_kyhizTb6Ir.jpg`,
        slug:`magicbaby`,
    },

    ///Magic Club///
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/154_jSWrYOMap.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/148_4mh85x9_a.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/155_UkwBmxQ9T.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/074_nTS4EY6auZ.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/020_yTr1qd8Vh.jpg`,
        slug:`magicclub`,
    },

    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/072_Ukt494N9Fg.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/090_pDY7HlxzYg.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/035_piEDcuF_a.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/083_--8VOHRuOO.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/078_f4r80o3NxF.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/115_YxmXVUx0e-.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/146_3tot1eWQUk.jpg`,
        slug:`magicclub`,
    },
    {
        brand:`Magic Club`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub/144_Ij_ap7A5K.jpg`,
        slug:`magicclub`,
    },

    ///Magic Wear///
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1018_s7I3-yJL7.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1122_UgO7X5eyQ6.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1008_Mu_wJ4Jnpn.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1105-damaged_4hHRfPAA8B.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1089_EaFuJFMfdM.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1107_AQdOlRyX4e.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1101-damaged_k-lLTBFQkb.jpg`,
        slug:`magicwear`,
    },
    {
        brand:`Magic Wear`,
        imgSrc:`https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear/1111_djiFjn45hF.jpg`,
        slug:`magicwear`,
    },
]

export default ImageList