import React, { useEffect } from 'react'
import ContactForm from '../../components/Form/ContactForm'
import "./Contact.css"

import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt, FaFacebook } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { AiFillInstagram } from "react-icons/ai";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    <div className="header-section">

      <h1>Contact Us</h1>
    </div>
    
    <div className="full-content-section"
      style={{marginBottom:`15vh`}}
    >
      <div className="two-grid-box">
        
        <ContactForm />
        
        <div className='contact-info-section'>

          <h3> Reach Us</h3>

          <div className="contact-item">
            <FaPhoneAlt className='contact-icon'/>

            <a href='tel: +919830975522' aria-label='' className='contact-link'>
              +91 9830975522
            </a>
          </div>

          <div className="contact-item">
            <IoMailSharp className='contact-icon'/>
            <a href="mailto:info@mmgroupfashion.com" aria-label=''  
              className='contact-link'
            >
              info@mmgroupfashion.com
            </a>
          </div>
          
          <div className="contact-item">
            <FaLocationDot className='contact-icon'/>
            <a 
              className='contact-link'
              href='https://www.google.com/maps/place/88,+Muktaram+Babu+St,+College+Street+Market,+College+Street,+Kolkata,+West+Bengal+700007/@22.5822942,88.3601134,17z/data=!3m1!4b1!4m6!3m5!1s0x3a0277b4ace5cbab:0x199cfa629b8081ba!8m2!3d22.5822942!4d88.3626883!16s%2Fg%2F11c1y3cv9g?entry=ttu' 
              target="_blank" rel="noreferrer" aria-label='' 
            >
              <p>
                88 Muktaram Babu Street, 
              </p>
              
              <p>
                Near Ram Mandir, 
              </p>
              
              <p>
                Kolkata-700007
              </p>
            </a>
          </div>

          <div className="contact-item" style={{marginTop:`1rem`}}>
            <a 
              href='https://www.instagram.com/mmgroupfashion/' 
              target="_blank" rel="noreferrer" aria-label=''
              className='contact-link'
            >

              <AiFillInstagram  className='contact-icon contact-insta'/>
            </a>

            <a 
              href='https://www.facebook.com/mmgroupfashion/' 
              target="_blank" rel="noreferrer" aria-label=''
              className='contact-link'
            >

              <FaFacebook className='contact-icon contact-fb'/>
            </a>

          </div>

        </div>
        
      
      </div>
    </div>
    </>
  )
}

export default Contact