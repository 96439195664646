import React from 'react'

function NotFound() {
  return (
    <section className="full-content-section not-found-box">
      {/* <div className="not-found-box"> */}

        <h1>404</h1>
        <h2>Page Not Found</h2>
      {/* </div> */}
    </section>
  )
}

export default NotFound