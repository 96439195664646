import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import brandList from '../../components/Lists/BrandList';
import ImageList from '../../components/Lists/ImageList';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function BrandsMobile() {
    const params = useParams();
    const navigate = useNavigate();

    const arrowImgSrc = "https://ik.imagekit.io/qljqw3tvn/MMGroup/misc/ArrowPink_vC7b3X3MQ.svg"


    const [ nextBrandFlag, setNextBrandFlag ] = useState(false)
    const [ animFlag, setAnimFlag ] = useState(false)

    const currentBrandImgList = ImageList.filter((x)=>x.slug===params.brand)

    const [currentBrandNum, setCurrentBrandNum] = useState(brandList.map(x=>x.slug).indexOf(params.brand))
    const [nextBrandNum, setNextBrandNum] = useState(
        currentBrandNum+1 === brandList.length ?
        0:currentBrandNum +1
    )
  ///////////////////////////////////////////
  /////////////Functions/////////////////////
  ///////////////////////////////////////////

  const handleNextBtnClick =()=>{
    let nextIndex
    if(currentBrandNum>=brandList.length-1){
      nextIndex = 0
    }else{
      nextIndex=currentBrandNum+1
    }

    let nextBrand
    if(nextBrandNum>=brandList.length-1){
      nextBrand = 0
    }else{
      nextBrand = nextBrandNum+1
    }

    setCurrentBrandNum(nextIndex)
    setNextBrandNum(nextBrand)
    navigate(`/brands/${brandList[nextIndex].slug}`)

  }

  const handlePrevBtnClick =()=>{
    setAnimFlag(true)

    setTimeout(()=>{
      setAnimFlag(false)
    }, 1500)

    let prevIndex
    if(currentBrandNum<=0){
      prevIndex=brandList.length-1
    }else{
      prevIndex=currentBrandNum-1
    }

    let nextBrand
    if(currentBrandNum<=0){
      nextBrand = 0
    }else{
      nextBrand = currentBrandNum
    }

    setCurrentBrandNum(prevIndex)
    setNextBrandNum(nextBrand)
    // let nextBrandSlug = brandList 
    navigate(`/brands/${brandList[prevIndex].slug}`)
  }

  return (
    <div className="brand-container">
        
        <div className="brand-grid-box">
            <div className="brand-grid-img-box">
                <img 
                    src={brandList[currentBrandNum].brandCoverImg}
                    alt="" 
                />
            </div>

            <div className="brand-logo-box-mobile">
                <img src={brandList[currentBrandNum].brandLogoSrc} alt="" />
            </div>

            <div className={`carousel-arrow-btn-left`}
                onClick={()=>handlePrevBtnClick()}
                style={{border:`none`}}
            >
                <FaChevronLeft 
                    style={{color:`white`, fontSize:`3rem`}}
                /> 
            </div>

            <div className="carousel-arrow-btn-right"
                onClick={()=>handleNextBtnClick()}
                style={{border:`none`}}
            >
                <FaChevronRight 
                    style={{color:`white`, fontSize:`3rem`}}
                />
            </div>

        </div>

        <div className="full-content-section">
        
        <div className="brand-image-grid">
            {currentBrandImgList.map((brand,index)=>{
                return(
                    <div className='brand-portfolio-box'
                        key={index}
                    >
                        <img className='brand-portfolio-img'
                            src={brand.imgSrc}
                            alt=''
                        />
                    </div>
                  )
              })}
        </div>
      
      </div>
    </div>
  )
}

export default BrandsMobile