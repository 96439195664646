import React, { useState } from 'react'
import "./Form.css"
import { AnimatePresence } from 'framer-motion'

function ContactForm() {
    const [errorPopup, setErrorPopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)

    const [formData, setFormData] = useState({
        userName:'',
        phone:'',
        userEmail:'',
        userFeedback:'',
    })

    const {userName, phone, userEmail, userFeedback} = formData

    /////////////////////////////////////////////////
    //////// Functions /////////////////////////////
    ////////////////////////////////////////////////

    const onChange = (e) => {
        setFormData((prevState)=>({
            ...prevState, 
            [e.target.name] : e.target.value,
        }))
        // console.log(`Entered onChange`)
    }

    const handleNumField = (e) => {
        const value = e.target.value.replace(/[^+-0123456789\s]/g, "");
        // const value = tempvalue.replace(/[]/g,)
        setFormData((prevState)=>({...prevState, [e.target.name]:value}));
      };

    const onSubmit = (e) => {
        e.preventDefault()
        
        if(userName==='' || userEmail==='' || phone==='' || userFeedback==='' ){
            setErrorPopup(true)
            setTimeout(()=>{
                setErrorPopup(false)
              },2000)
        }else{

            const userData = {
                userName, userEmail, phone
            }
            console.log(`userData: ${JSON.stringify(userData,null,4)}`)

            setSuccessPopup(true)
                setTimeout(()=>{
                    setSuccessPopup(false)
                },2000)

            // emailjs.sendForm(service_ID,
            //     template_ID, 
            //     e.target,
            //     email_Key)
            //     .then(res=>{
            //         // console.log(res)
            //             setSuccessPopup(true)
            //             setTimeout(()=>{
            //                 setSuccessPopup(false)
            //             },4000)
            //     }).catch(err=> console.log(err))
        }
    }
    
  return (
    <div className='contact-form-container'>
        <form onSubmit={onSubmit} className='form-container'>
            
            <h3>Ask Us Anything</h3>
            <div className="form-grid">

                <div className="form-group">
                    <label>Name *</label>
                    <input type = "text" 
                        className="form-control" 
                        name = 'userName'
                        id = 'userName'
                        value = {userName} 
                        // placeholder= 'Name *'
                        onChange = {onChange}
                        autoComplete="off"
                        required
                        />
                </div>


                <div className="form-group" style={{gridColumn: `1 / span 2`}}>
                    <label>E-mail *</label>
                    <input type="email" 
                        className="form-control" 
                        name='userEmail'
                        id='userEmail' 
                        value={userEmail} 
                        // placeholder='E-mail'
                        onChange={onChange}
                        autoComplete="off"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Phone *</label>
                    <input type="text" 
                        className="form-control" 
                        name='phone'
                        id='phone' 
                        value={phone} 
                        // placeholder='Phone Number *'
                        onChange={handleNumField}
                        required
                        // onKeyPress={(e) => !/[0-9,.,+, ]/.test(e.key) && e.preventDefault()}
                         />
                </div>

                <div className="form-group">
                    <label>Comments</label>
                    <textarea type="text" rows="4"
                        className="form-control form-control-textarea" 
                        name='userFeedback'
                        id='userFeedback' 
                        value={userFeedback} 
                        placeholder=''
                        onChange={onChange}
                        required 
                        />
                </div>


                    <div className="form-group">
                        <button type="submit" className="submit-btn">
                            Submit
                        </button>
                    </div>
                <div className="alert-submit-box">


                    <AnimatePresence>
                        {errorPopup &&
                            <div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            >
                                <div className="error-alert">
                                    Please fill all the fields
                                </div>
                            </div>
                        } 
                    </AnimatePresence>

                    <AnimatePresence>
                        {successPopup && 
                         <div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            > 
                                <div className="error-alert success-alert">
                                    We will get back to you soon!
                                </div>
                        </div>
                        }
                    </AnimatePresence>

                </div>
            </div>
            
            

        </form>
    </div>
  )
}

export default ContactForm