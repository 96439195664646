import React, { useEffect } from 'react'
import PartnerDropdown from '../../components/Dropdown/PartnerDropdown'
import { useMediaQuery } from 'react-responsive'

function About() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  const topImgSrc = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/MM3123_6d4V6WkXU.jpg'
  const secondImgSrc ='https://ik.imagekit.io/qljqw3tvn/MMGroup/_DSC5375_UIvITtQ-Iy.jpg'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    <section className="half-grid-section"
      style={{
        marginTop:`var(--navbarHeight)`,
        backgroundColor:`var(--brandColourRed)`
        // d4ebff
      }}
    >

      {/* Top */}
      {/* <div className="custom-shape-divider-top-1715065671">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
      </div> */}

      {/* Bottom */}
      <div className="custom-shape-divider-bottom-1715064441">
          <svg data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div>
        
      <div className="half-grid-img-box"
        style={isSmallWindow?{width:`100%`}:{}}
      >
        <img className='half-grid-img'  
          src={topImgSrc} alt="" />
      </div>

      <div className="half-grid-content-box"
        // style={{alignItems:`center`}}
      >

        {/* <h1 style={{color:`white`}}>Mission</h1> */}
        <p style={{color:`white`}}>
          Since the foundation of our company in 2002, our misson has been to create enchanting designs for girls clothing  
          
          {/* creating pieces of art to serve girls who are passionate about the 
          world of fashion and who express themselves through their clothes. */}
          </p>
        
        <p style={{color:`white`}}>
        Discover timeless elegance and playful charm with our curated collection of girls' clothing, designed to inspire confidence and creativity. From everyday essentials to special occasion ensembles, find the perfect style for every little girl's unique personality.
        </p>

      </div>
    </section>

    <section className="half-grid-section"
      style={isSmallWindow?{}:{marginBottom:`20vh`}}
    >
      {/* Top */}
      {/* <div className="custom-shape-divider-top-test">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>            </svg>
      </div> */}
                {/* <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path> */}

      {/* Bottom */}
      {/* <div className="custom-shape-divider-bottom-1715064441">
          <svg data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div> */}

      <div className="half-grid-content-box">
        {/* <h1>Vision</h1> */}
        <p>
          Our team commits to the highest standards of 
          efficiency, reliability and innovation.
        </p>

        <p>
        Our designs are crafted by a team of passionate creators who blend modern trends with classic charm, ensuring each piece reflects our commitment to quality and style. With an eye for detail and a love for imaginative flair, our designers bring dreams to life in every stitch and seam.
        </p>

        {/* <p> About Us #3</p> */}
      </div>

      <div className="half-grid-img-box "
        style={{width:`100%`}}
      >
        <img className='half-grid-img right-half-img-box'  
          src={secondImgSrc} 
          alt="" 
          style={isSmallWindow?{borderRadius:`0`}: {borderRadius:`25% 0 0 30%`}}
          
          />
      </div>

    </section>
    {/* <section style={{width:`100%`, height:`20vh`}}></section> */}
    </>
  )
}

export default About