import React, { useEffect } from 'react'
import CoverCarousel from '../../components/CoverCarousel/CoverCarousel'
import HalfCarousel from '../../components/HalfCarousel/HalfCarousel'

import "./Home.css"
import Marquee from '../../components/Marquee/Marquee'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Brand from '../../components/Brand/Brand'

function Home() {
  const navigate = useNavigate();
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  const miniAngelLogoSrc = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MINI%20ANGEL_kdIrX3kZp.png'
  const miniAngelPopup = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniMagic_popup_Ws2kTV7Cp.png'
  
  const magicWearLogoSrc = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/Magic%20Wear%20LOGO_OjyrpJSru.png'
  const magicWearPopup = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicWear_popup_h6bvXXo2Q.png'

  const magicBabyLogoSrc = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MAGIC%20BABY_VJW_W9PLpE.png'
  const magicBabyPopup = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicBaby_popup_29XI4dlBt.png'

  const miniMagicLogoSrc = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MINI%20MAGIC_PLgnQUN4mv.png'
  const miniMagicPopup = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/MiniAngel_popup_K9ZSc2fmt.png'
  
  const magicClubLogoSrc = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MAGIC%20CLUB-LOGO_rBd3pcTLPE.png'
  const magicClubPopup = 'https://ik.imagekit.io/qljqw3tvn/MMGroup/MagicClub_popup_xcZG6gz_M5.png'

  const aboutUsImgSrc = "https://ik.imagekit.io/qljqw3tvn/MMGroup/MM3123_6d4V6WkXU.jpg"


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <CoverCarousel />

    {/* <div className="half-content-section"
      style={{
        backgroundColor:'#ffc8dd',
        borderTop:`5px solid var(--brandColourRed )`
      }}
    >
      <div className="custom-shape-divider-bottom-1715064441">
          <svg data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
      </div>
    </div> */}

    <section className="half-grid-section"
      style={{margin:`10vh 0`}}
    >

    {/* Top */}
      {/* <div className="custom-shape-divider-top-1715065671">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
      </div>  */}

      <div className="half-grid-img-box"
      >
        <img className='half-grid-img'  
          src={aboutUsImgSrc} alt="" 
          style={{borderRadius:`0 25% 30% 0`}}
          />
      </div>

      <div className="half-grid-content-box">

        <h1>Know us better!</h1>
        <br />
        <p>With a family that has been growing since 2002
            we have a lot to share
        </p>
        
        <p>
          Get an insight into the creative process that goes into turning fabric into smiles
        </p>
        
        <br />
        
        <div className='brand-explore-btn'
          onClick={()=>navigate("/about-us")}
        >
          Explore
        </div>
      </div>
      
    </section>

    {/* Brands */}
    <Brand />


    {/* <section className="half-content-section">
        <HalfCarousel />  
    </section> */}

    {/* <section className="half-content-section">

      <div className="half-carousel-heading-container">
        <h2>Our Partners</h2>
      </div>
        
      <Marquee />
    </section> */}

    <section className="full-content-section"
      style={{margin:`10vh 0 20vh`}}
    >
      
      {/* ////////////////// */}
      <div className="half-carousel-heading-container styled-text"
        style={{
          // marginBottom:`5vh`,
          padding:`150px 0 0 0`
        }}
        >


        {/* stars */}
        <aside className="star">
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`50%`,left:`80%`, width:`5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`40%`,left:`5%`, width:`5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`20%`,left:`90%`, width:`2rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`20%`,left:`60%`, width:`3.5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`60%`,left:`15%`, width:`6rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`30%`,left:`95%`, width:`5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        {/* stars */}

          {/* <h2></h2> */}
          <h1 style={{fontSize:`3rem`}}>
            Colorful Outfits for the 
          </h1>
          <h1>Little Adventurers!</h1>

        
          {/* Top */}
        <div className="custom-shape-divider-top-1715065671">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
        </div>

        {/* Bottom */}
        {/* <div className="custom-shape-divider-bottom-1715064441">
          <svg data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div> */}

      </div>

      {/* ////////// */}

      <div className="full-img-container">
        {/* Top */}
        <div className="custom-shape-divider-top-1715065671">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"
                  style={{fill:`#D4EBFF`}}
                ></path>
            </svg>
        </div>

        {/* Bottom */}
        <div className="custom-shape-divider-bottom-1715064441">
          <svg data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div> 

        <img
          className='full-content-section-img'
          src="https://ik.imagekit.io/qljqw3tvn/MMGroup/MM3312_SGpVQcchr.jpg" 
          alt="" />
      </div>  
    
    </section>

    </>
  )
}

export default Home