import React from 'react'
import brandList from '../Lists/BrandList'
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function Brand() {
  const navigate = useNavigate();
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' })

  return (
    <section className='full-content-section'
    >
      <div className="half-carousel-heading-container styled-text"
      style={{marginBottom:`5vh`,
        padding:`120px 0`
      }}
      >
        {/* stars */}
        <aside className="star">
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`50%`,left:`80%`, width:`5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`40%`,left:`5%`, width:`5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`20%`,left:`90%`, width:`2rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`20%`,left:`60%`, width:`3.5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`60%`,left:`15%`, width:`6rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>

        <aside className="star"
          style={{top:`30%`,left:`95%`, width:`5rem`}}
        >
          <img src="./images/Star1.svg" alt="" />
        </aside>
        {/* stars */}

        <h1 style={isSmallWindow?{fontSize:`1.8rem`}:{fontSize:`3rem`}}>
            From the house of 
        </h1>
        <h1>
          MM Group Fashion
        </h1>

        {/* Top */}
        <div className="custom-shape-divider-top-1715065671">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
            </svg>
        </div>

        {/* Bottom */}
        <div className="custom-shape-divider-bottom-1715064441">
          <svg data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 1200 120" 
            preserveAspectRatio="none"
          >
              <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
          </svg>
        </div>

      </div>

      {brandList.map((brandItem, index)=>
      <React.Fragment key={index}>
        {
            isSmallWindow?
                <div className="brand-half-section">
                    <div className="brand-half-img-container"
                        onClick={()=>navigate(`/brands/${brandItem.slug}`)}
                        >
                        <img 
                            src={brandItem.brandCoverImg} alt="" 
                            className="brand-half-img" />
                    </div>

                    <div className="brand-half-text">
                        <img className='brand-half-logo-img' 
                            src={brandItem.brandLogoSrc} 
                            alt=""     
                            />

                        <div className='brand-explore-btn'
                            onClick={()=>navigate(`/brands/${brandItem.slug}`)}
                            >
                            Explore
                        </div>
                    </div>

                </div>
            :
        
            <>
          {index%2===0 ?
            <div className="brand-half-section">
                <div className="brand-half-img-container"
                    onClick={()=>navigate(`/brands/${brandItem.slug}`)}
                    >
                    <img 
                        src={brandItem.brandCoverImg} alt="" 
                        className="brand-half-img" />
                </div>

                <div className="brand-half-text">
                    <img className='brand-half-logo-img' 
                        src={brandItem.brandLogoSrc} 
                        alt=""     
                        />

                    <div className='brand-explore-btn'
                        onClick={()=>navigate(`/brands/${brandItem.slug}`)}
                        >
                        Explore
                    </div>
                </div>

            </div>
            :
            <div className='brand-half-section right-brand-half-section'>
                
                <div className="brand-half-text right-half-text"> 
                    <img className='brand-half-logo-img' 
                        src={brandItem.brandLogoSrc} alt=""
                    />

                    <div className='brand-explore-btn'
                        onClick={()=>navigate(`/brands/${brandItem.slug}`)}
                        >
                        Explore
                    </div>

                </div>
                
                <div className="brand-half-img-container right-half-img-container"
                    onClick={()=>navigate("/brands/miniangel")}
                    >
                        <img className="brand-half-img"
                            src={brandItem.brandCoverImg} 
                            alt="" 
                            />
                </div>

            </div>
            }
            </>
        }
        </React.Fragment>
        
    )}
    
     {/* return(
    ) */}
    
      {/* <div className="brand-half-section">
        
        <div className="brand-half-img-container"
          onClick={()=>navigate("/brands/minimagic")}
        >
          <img 
            src={miniMagicPopup} alt="" 
            className="brand-half-img" />
        </div>
        
        <div className="brand-half-text">
          <img className='brand-half-logo-img' 
            src={miniMagicLogoSrc} 
            alt="" 
            
            />

          <div className='brand-explore-btn'
            onClick={()=>navigate("/brands/minimagic")}
          >
            Explore
          </div>
        </div>
        </div> */}
      
        {/* /////2//// */}
      {/* <div className="brand-half-section right-brand-half-section">

        <div className="brand-half-text right-half-text"> 
            <img className='brand-half-logo-img' src={miniAngelLogoSrc} alt=""
            />

            <div className='brand-explore-btn'
                onClick={()=>navigate("/brands/miniangel")}
            >
                Explore
            </div>
        </div>

        <div className="brand-half-img-container right-half-img-container"
        onClick={()=>navigate("/brands/miniangel")}
        >
            <img src={miniAngelPopup} alt="" className="brand-half-img" />
        </div>

        </div> */}

    </section>
  )
}

export default Brand