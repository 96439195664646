import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { MdMenu } from "react-icons/md";

function NavbarMobile() {
    const navigate = useNavigate();
    const logoImgSrc = "https://ik.imagekit.io/qljqw3tvn/MMGroup/logos/MM_GROUP_logo_ll6R1mm_7.png"
    
    const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' }) 
  
    const [scrollDir, setScrollDir] = useState("up");
    const [offset, setOffset] = useState(0);

    const [menuFlag, setMenuFlag] = useState(false)

    const navList = [
        {
            btnName:`About`,
            path:`/about-us`
        },
        {
            btnName:`Brands`,
            path:`/brands/magicclub`
        },
        {
            btnName:`Partners`,
            path:`/partners`
        },
        {
            btnName:`Careers`,
            path:`/careers`
        },
        {
            btnName:`Contact`,
            path:`/contact-us`
        },
    ]

    const handleNavClick = (path) =>{
        navigate(`${path}`)
        setMenuFlag(false)
    }

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateScrollDir = () => {
        const scrollY = window.scrollY;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
            ticking = false;
            return;
        }
        setScrollDir(scrollY > lastScrollY ? "down" : "up");
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
        };

        const onScroll = () => {
        if (!ticking) {
            window.requestAnimationFrame(updateScrollDir);
            ticking = true;
        }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);

  return (
    <>
   <div className={`navbar-container`}>
        <div className="navbar-logo-box">
            <img className="nav-logo-img"
                src={logoImgSrc} 
                alt="MM Fashion"  
                onClick={()=>navigate("/")}
                />
        </div>
        
        <div className="navbar-menu-btn"
            onClick={()=>setMenuFlag(!menuFlag)}
        >
            <MdMenu 
                style={{color:`var(--brandColourRed)`, fontSize:`2rem`}}
            />
        </div>
    </div>
    
    {/* {menuFlag && */}
        <div className={`navbar-menu-container ${menuFlag? ``: `menu-hidden`}`}
        >

            {navList.map((navItem,index)=>{
                return(
                    <div className='nav-btn nav-btn-mobile'
                        key={index}
                        onClick={()=>handleNavClick(`${navItem.path}`)}
                    >
                        {navItem.btnName}
                    </div>
                )
            })}
        
        </div>
    {/* } */}
    </>
  )
}

export default NavbarMobile