import React, { useState } from 'react'
import partnerList from '../Lists/PartnerList'
import PartnerDDItem from './PartnerDDItem'

function PartnerDDMobile() {
    const [activeIndex, setActiveIndex] = useState(null)


    const handleDDClick = (index) =>{
        if(index===activeIndex){
            setActiveIndex(null)
        }else{
            setActiveIndex(index)
        }
    }

  return (
    <div className='partner-dd-mobile-container'>
        <div className="partner-list-box">
            {partnerList.map((itemData,index)=>{
                return(
                    <React.Fragment key={index}>
                    <div className='partner-cell-box'
                        onClick={()=>handleDDClick(index)}
                    >
                        <p className='partner-cell-text cell-num'>
                            {index + 1}
                        </p>
                        <p className='partner-cell-text'>
                            {itemData.partnerName}
                        </p>
            
                    {activeIndex===index &&
                    <div className="partner-info-box partner-info-mobile-box">
            
                    <section className="partner-info-mobile-details">
                            {itemData.partnerAddress.map((addLine,i)=>{
                                return (
                                        <p className='partner-info-para'
                                            style={{color:`white`}}
                                            key={i}
                                        >
                                            {addLine}
                                        </p>
                                )                
                                }
                            )}
                
                            <br />
                
                            <p className='partner-info-para'
                                style={{color:`white`}}
                            >
                                {itemData.partnerPhone}
                            </p>
                        
                        </section>
                    </div>}
                </div>
        
                </React.Fragment>
                )
            })
            }
        </div>
    </div>
  )
}

export default PartnerDDMobile