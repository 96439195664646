import React, {useState} from 'react'
import "./Marquee.css"
import { useNavigate } from 'react-router-dom';

function Marquee() {
    const navigate = useNavigate();
    const [onHoverFlag, setOnHoverFlag ] = useState(false)

    const imgList = [{
            imgSrc:"./images/AJIO_2.avif",
            partnerUrl:'https://www.ajio.com/'
        },{
            imgSrc:"./images/LIFESTYLE.avif",
            partnerUrl:"https://www.lifestylestores.com/in/en",
        },{
            imgSrc:"./images/MYNTRA_1.avif",
            partnerUrl:"https://www.myntra.com/",
        },{
            imgSrc:"./images/SHOPPERSTOP.avif",
            partnerUrl:"https://www.shoppersstop.com/",
        },
        {
            imgSrc:"./images/AJIO_2.avif",
            partnerUrl:"https://www.ajio.com/",
        },{
            imgSrc:"./images/LIFESTYLE.avif",
            partnerUrl:"https://www.lifestylestores.com/in/en",
        },{
            imgSrc:"./images/MYNTRA_1.avif",
            partnerUrl:"https://www.myntra.com/",
        },{
            imgSrc:"./images/SHOPPERSTOP.jpg",
            partnerUrl:"https://www.shoppersstop.com/",
        },
    ]


  return (
    <>
    <div className='marquee-container' 
        onMouseEnter={()=>setOnHoverFlag(true)}
        onMouseLeave={()=>setOnHoverFlag(false)}    
    >
        <div className="marquee-group"
            style={onHoverFlag?{animationPlayState:`paused`}:{}}
        >
            {imgList.map((img, index)=>{
                return(
                    
                    <div className="marquee-img-group" key={index}
                        // onClick={()=>navigate(img.partnerUrl)}
                        >
                    <a href={img.partnerUrl} target="_blank" rel="noreferrer">
                        <img src={img.imgSrc} alt="" 
                            className="marquee-item-img" 
                            />
                    </a>
                    </div>
                )
            })}
        </div>

        <div className="marquee-group"
            style={onHoverFlag?{animationPlayState:`paused`}:{}}
        >
            {imgList.map((img, index)=>{
                return(     
                    <div className="marquee-img-group" key={index}
                        // onClick={()=>navigate(img.partnerUrl)}
                        >
                        <a href={img.partnerUrl} target="_blank" rel="noreferrer">
                        <img src={img.imgSrc} alt="" 
                            className="marquee-item-img" 
                            />
                        </a>
                    </div>
                )
            })}
        </div>

    </div>
    </>
  )
}

export default Marquee