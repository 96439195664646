import React, { useEffect, useState } from 'react'
// import { GoArrowLeft, GoArrowRight } from "react-icons/go";
// import { HiArrowRight, HiArrowLeft } from "react-icons/hi2";
// import { PiArrowRightThin, PiArrowLeftThin } from "react-icons/pi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import "./Brands.css"

import ImageList from '../../components/Lists/ImageList';
import brandList from '../../components/Lists/BrandList';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import BrandsMobile from './BrandsMobile';

function Brands() {
  const params = useParams();
  const navigate = useNavigate();
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' }) 

  const arrowImgSrc = "https://ik.imagekit.io/qljqw3tvn/MMGroup/misc/ArrowPink_vC7b3X3MQ.svg"

  
  const [ nextBrandFlag, setNextBrandFlag ] = useState(false)
  const [ animFlag, setAnimFlag ] = useState(false)
  
  const currentBrandImgList = ImageList.filter((x)=>x.slug===params.brand)
  
  const [currentBrandNum, setCurrentBrandNum] = useState(brandList.map(x=>x.slug).indexOf(params.brand))
  const [nextBrandNum, setNextBrandNum] = useState(
    currentBrandNum+1 === brandList.length ?
      0:currentBrandNum +1
  )

  ///////////////////////////////////////////
  /////////////Functions/////////////////////
  ///////////////////////////////////////////

  const handleNextBtnClick =()=>{
    let nextIndex
    if(currentBrandNum>=brandList.length-1){
      nextIndex = 0
    }else{
      nextIndex=currentBrandNum+1
    }

    let nextBrand
    if(nextBrandNum>=brandList.length-1){
      nextBrand = 0
    }else{
      nextBrand = nextBrandNum+1
    }

    setCurrentBrandNum(nextIndex)
    setNextBrandNum(nextBrand)
    navigate(`/brands/${brandList[nextIndex].slug}`)

  }

  const handlePrevBtnClick =()=>{
    setAnimFlag(true)

    setTimeout(()=>{
      setAnimFlag(false)
    }, 1500)

    let prevIndex
    if(currentBrandNum<=0){
      prevIndex=brandList.length-1
    }else{
      prevIndex=currentBrandNum-1
    }

    let nextBrand
    if(currentBrandNum<=0){
      nextBrand = 0
    }else{
      nextBrand = currentBrandNum
    }

    setCurrentBrandNum(prevIndex)
    setNextBrandNum(nextBrand)
    // let nextBrandSlug = brandList 
    navigate(`/brands/${brandList[prevIndex].slug}`)
  }

  const handlePrevMouseHoverEnter = ()=>{
    if(currentBrandNum<=0){
      setNextBrandNum(brandList.length-1)
    }else{
      setNextBrandNum(currentBrandNum-1)
    }  

    setNextBrandFlag(true)
  }

  const handlePrevMouseHoverExit = ()=>{
    if(currentBrandNum>=brandList.length-1){
      setNextBrandNum(0)
    }else{
      setNextBrandNum(currentBrandNum+1)
    }
    setNextBrandFlag(false)
  }

  function ImageMagnifier({
    src,
    width,
    height,
    magnifierHeight = 200,
    magnifieWidth = 200,
    zoomLevel = 1.5
  }) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    return (
      <div className='brand-portfolio-box'
        style={{
          position: "relative",
          height: height,
          width: width,
        }}
      >
        <img className='brand-portfolio-img'
          src={src}
          style={{ height: height, width: width }}
          onMouseEnter={(e) => {
            // update image size and turn-on magnifier
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setSize([width, height]);
            setShowMagnifier(true);
          }}
          onMouseMove={(e) => {
            // update cursor position
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();
  
            // calculate cursor position on the image
            const x = e.pageX - left - window.scrollX;
            const y = e.pageY - top - window.scrollY;
            setXY([x, y]);
          }}
          onMouseLeave={() => {
            // close magnifier
            setShowMagnifier(false);
          }}
          alt={"img"}
        />
  
        <div className='img-maginfy-box'
          style={{
            display: showMagnifier ? "" : "none",
            position: "absolute",
  
            cursor:`none`,
            // prevent magnifier blocks the mousemove event of img
            pointerEvents: "none",
            // set size of magnifier
            height: `${magnifierHeight}px`,
            // height: `200%`,
            width: `${magnifieWidth}px`,
            // width: `200%`,
            // move element center to cursor pos
            top: `${y - magnifierHeight / 2}px`,
            left: `${x - magnifieWidth / 2}px`,
            opacity: "1", // reduce opacity so you can verify position
            border: "1px solid var(--brandColourRed)",
            borderRadius:`50%`,
            backgroundColor: "white",
            backgroundImage: `url('${src}')`,
            backgroundRepeat: "no-repeat",
  
            //calculate zoomed image size
            backgroundSize: `${imgWidth * zoomLevel}px ${
              imgHeight * zoomLevel
            }px`,
  
            //calculate position of zoomed image.
            backgroundPositionX: `${-x * zoomLevel + magnifieWidth / 2}px`,
            backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
          }}
        ></div>

      </div>
    );
  }
  
  
  ///////////////////////////////////////////
  /////////////Hooks/////////////////////////
  ///////////////////////////////////////////
  useEffect(()=>{
    if(currentBrandNum>=brandList.length-1){
      setNextBrandNum(0)
    }
    // console.log(`nextBrandNum:${nextBrandNum}`)
  },[currentBrandNum])

  useEffect(()=>{
    // console.log(`pB:${params.brand}`)
    // console.log(currentBrandImgList)
    // console.log(`CB:${JSON.stringify(brandList.filter((x)=>x.slug===params.brand),null,4)}`)
    // console.log(`cBN:${currentBrandNum}`)
  },[])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <>
    {brandList.filter(x=>x.slug===params.brand).length===0 ?
    <>
    <section className="full-content-section not-found-box">
      {/* <div className="not-found-box"> */}

        <h1>404</h1>
        <h2>Page Not Found</h2>
      {/* </div> */}
    </section>
    </>
    :
    <>
    {isSmallWindow?
      <BrandsMobile />
      :
      <>
      <div className="brand-container">
      
        <div className="brand-grid-box">

          {/* main image */}
          <div className="brand-grid-img-box">
            <img 
              src={brandList[currentBrandNum].brandCoverImg}
              // "https://ik.imagekit.io/qljqw3tvn/MMGroup/MM3162_iTyqYYfGh.jpg" 
              alt="" 
            />
          </div>

          {/* brand content */}
          <div className="brand-grid-content-box">
            {/* <h1>0{currentBrandNum}</h1> */}
            
            <div className="next-brand-arrow-container">
              <div>

              <FaArrowLeft className='next-icon'
                onClick={()=>handlePrevBtnClick()}
                // onMouseEnter={()=>setNextBrandFlag(true)}
                // onMouseLeave={()=>setNextBrandFlag(false)}
                onMouseEnter={()=>handlePrevMouseHoverEnter()}
                onMouseLeave={()=>handlePrevMouseHoverExit()}
                />
              <FaArrowRight className='next-icon'
                onClick={()=>handleNextBtnClick()}
                onMouseEnter={()=>setNextBrandFlag(true)}
                onMouseLeave={()=>setNextBrandFlag(false)}
              />

              {/* <img src="./images/arrowRight.svg" alt="" 
                onClick={()=>handleNextBtnClick()}
                onMouseEnter={()=>setNextBrandFlag(true)}
                onMouseLeave={()=>setNextBrandFlag(false)}
              /> */}
              </div>

              {/* <p>0{nextBrandNum}</p> */}
            </div>
            
            <div className="next-brand-box"
            // style={animFlag?{animationPlayState:`running`}:{}}
              style={nextBrandFlag?{filter:`grayscale(0)`,transition:`0.6s ease-in-out`}:{}}
            >
              <img 
              className='next-brand-coverImg'
                src={brandList[nextBrandNum].brandCoverImg}
                alt="" 
              />

              <img 
                className="brand-next-logo" 
                src={brandList[nextBrandNum].brandLogoSrc} 
                alt="" 
              />
            
            </div>

            <div className="brand-logo-box">
              <img 
                src={brandList[currentBrandNum].brandLogoSrc}
                alt="" 
                />
            </div>

            {/* <div className="brand-grid-content-text">
              {brandList[currentBrandNum].brandDesc.map((para,index)=>{
                return(
                  <p key={index}>{para}</p>
                )
              })}
            </div>           */}

          </div>

        </div>
        
        <div className="brand-galery-heading-box">
          <p>Gallery</p>
          <img className='gallery-arrow-img' 
            src={arrowImgSrc} 
            alt="" />
          {/* <HiArrowRight className='next-icon gallery-arrow'/> */}
        </div>
      
      </div>

      <section className="full-content-section">
        
        <div className="brand-image-grid">
            {currentBrandImgList.map((brand,index)=>{
                return(
                  <ImageMagnifier 
                    key={index}
                    src={brand.imgSrc}
                    height={`100%`}
                    width={`100%`}
                    magnifierHeight={`100`}
                    magnifieWidth={`100`}
                    zoomLevel={`1.5`}
                    />
                  )
                // <div className='brand-portfolio-box' key={index}>
                //  <img key={index} className='brand-portfolio-img'
                // src={brand.imgSrc} alt=''
              // /> 
              // </div>
              })}
        </div>
      
      </section>
      </>
      }
    </>
    }
  </>
  )
}

export default Brands